<template>
  <div>
    <add-event :event-data.sync="eventData" />
    <div v-if="eventsList.length">
      <b-card>
        <created-events-list
          :selected-event-id.sync="selectedEventId"
          :selected-event-dates.sync="selectedEventDates"
        />
        <div ref="element">
          <sessions-table
            ref="parentTable"
            :selected-event-id="selectedEventId"
            :is-add-session-form-visible.sync="isAddSessionFormVisible"
            :is-edit-session-form-visible.sync="isEditSessionFormVisible"
            :selected-session-to-edit.sync="selectedSessionToEdit"
            :event-action-type.sync="eventActionType"
          />
        </div>
      </b-card>
    </div>
    <add-session
      v-if="isAddSessionFormVisible && !isEditSessionFormVisible"
      :selected-event-id="selectedEventId"
      :selected-event-dates="selectedEventDates"
      :event-action-type="eventActionType"
      @addSession="onAddSession()"
    />
    <edit-session
      v-if="isEditSessionFormVisible && !isAddSessionFormVisible"
      :entity-id="eventData.entity_id"
      :selected-session-to-edit="selectedSessionToEdit"
      :selected-event-dates="{start_time:eventData.start_time,end_time:eventData.end_time}"
      @editSession="onEditSession()"
    />
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import { mapGetters } from 'vuex'
import AddEvent from '@/common/components/PrayerBookings/Add/AddEvent.vue'
import SessionsTable from '@/common/components/PrayerBookings/Add/SessionsTable.vue'
import AddSession from '@/common/components/PrayerBookings/common/AddSession.vue'
import EditSession from '@/common/components/PrayerBookings/Edit/EditSession.vue'
import CreatedEventsList from '@/common/components/PrayerBookings/Add/CreatedEventsList.vue'
import scroll from '@/common/compositions/common/scrollToElement'

export default {
  name: 'AddPrayerBooking',
  components: {
    AddEvent,
    SessionsTable,
    AddSession,
    EditSession,
    CreatedEventsList,
  },
  data() {
    return {
      isAddSessionFormVisible: false,
      isEditSessionFormVisible: false,
      selectedSessionToEdit: null,
      selectedEventId: null,
      selectedEventDates: null,
      eventActionType: null,
      eventData: {},
    }
  },
  computed: {
    ...mapGetters({ eventsList: 'addEvents/getEventsList' }),
  },
  beforeDestroy() {
    this.resetCreatedEvents()
  },
  setup() {
    const { scrollToElement, element } = scroll()
    return { scrollToElement, element }
  },
  methods: {
    resetCreatedEvents() {
      this.$store.commit('addEvents/setEventsList', [])
    },
    onAddSession() {
      this.refreshSessionsTable()
      this.scrollToElement()
      this.hideAddSession()
    },
    hideAddSession() {
      this.isAddSessionFormVisible = false
    },
    onEditSession() {
      this.scrollToElement()
      this.hideEditSession()
    },
    hideEditSession() {
      this.isEditSessionFormVisible = false
    },
    refreshSessionsTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
