<template>
  <b-row ref="element">
    <b-col md="4">
      <label>Created Events</label>
      <v-select
        label="start_time"
        :options="eventsList"
        @option:selected="setSelectedEventData"
      />
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import scroll from '@/common/compositions/common/scrollToElement'

export default {
  name: 'CreatedEventsList',
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters({ eventsList: 'addEvents/getEventsList' }),
  },
  setup() {
    const { element, scrollToElement } = scroll()
    return {
      element, scrollToElement,
    }
  },
  mounted() {
    this.scrollToElement()
  },
  methods: {
    setSelectedEventData(eventData) {
      this.$emit('update:selected-event-id', eventData.id)
      this.$emit('update:selected-event-dates', { start_time: eventData.start_time, end_time: eventData.end_time })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
