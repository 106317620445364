<template>
  <b-card>
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent
      >
        <b-row align-h="end">
          <span>
            <feather-icon
              id="popover-button-variant"
              icon="AlertCircleIcon"
              size="20"
            />
            Define the differences
          </span>
          <b-popover
            target="popover-button-variant"
            variant="primary"
            triggers="focus"
            placement="top"
          >
            <template #title>
              <span>Instructions</span>
            </template>
            <div>
              <p>
                For Each date in the selected date range, an event will be created for example,<br><br>
                if admin user create event with occurrence:
              </p>
              <ul>
                <li class="mb-1">
                  <b>Once</b> : means one event will be created.
                </li>
                <li class="mb-1">
                  <b>Daily</b>: means count of created events are equal to the selected range.
                </li>
                <li class="mb-1">
                  <b>Weekly</b>: means that created events only match the selected weekdays in the selected date range.
                </li>
                <li>
                  <b>Selected Dates</b>: means that created events only match the selected dates.
                </li>
              </ul>
            </div>
          </b-popover>
        </b-row>

        <b-form-row>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Event Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="event-name"
                class="mb-2"
              >
                <b-form-input
                  id="event-name"
                  v-model="eventData.activity_name"
                  placeholder="Jomaa, Taraweeh, Eid Adha, Eid Fitr, etc"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Occurrence"
              rules="required"
            >
              <b-form-group
                label="Occurrence"
                label-for="event-occurrenceType"
              >
                <b-form-select
                  id="event-occurrenceType"
                  v-model="eventData.frequency"
                  :state="getValidationState(validationContext)"
                  :options="occurrenceTypes"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <div>
          <b-form-row class="my-2 my-lg-0">
            <b-col
              v-if="eventData.frequency === 'once'"
            >
              <b-form-row>
                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Start Date"
                    rules="required"
                    :detect-input="false"
                  >
                    <b-form-group
                      label="Start Date"
                    >
                      <flat-pickr
                        v-model="eventData.start_time"
                        class="form-control"
                        :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                        @input="validationContext.validate(eventData.start_time)"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="End Date"
                    rules="required"
                    :detect-input="false"
                  >
                    <b-form-group label="End Date">
                      <flat-pickr
                        v-model="eventData.end_time"
                        class="form-control"
                        :config="{
                          enableTime:true,
                          dateFormat: 'Y-m-d H:i',
                          minDate:$moment(eventData.start_time).add({minutes:1}).format('YYYY-MM-DD HH:mm') }"
                        @input="validationContext.validate(eventData.end_time)"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col
              v-if="eventData.frequency === 'selected_dates'"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Dates"
                rules="required"
              >
                <b-form-group>
                  <h5>Selected Dates</h5>
                  <flat-pickr
                    v-model="multiDate"
                    class="form-control"
                    :config="{ mode: 'multiple',dateFormat: 'Y-m-d', minDate: 'today'}"
                    @input="convertMultiDateToArray();validationContext.validate(multiDate)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="eventData.frequency === 'daily' || eventData.frequency === 'weekly'"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Date Range"
                :detect-input="false"
              >
                <b-form-group>
                  <h5>Date Range</h5>
                  <flat-pickr
                    v-model="dateRange"
                    class="form-control"
                    :config="{ mode: 'range', dateFormat: 'Y-m-d', minDate: 'today'}"
                    @input="convertDateRangeToArray();validationContext.validate(dateRange)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="eventData.frequency === 'weekly'"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Weekly"
                rules=""
                :detect-input="false"
              >
                <b-form-group>
                  <h5>Selected Days</h5>
                  <v-select
                    v-model="eventData.selected_dates"
                    :options="weekDays"
                    :reduce="day=>day.name"
                    label="name"
                    multiple
                    :close-on-select="false"
                    @input="validationContext.validate(eventData.selected_dates)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>
          <b-form-row v-if="eventData.frequency !== 'once'">
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Start Time"
                rules="required"
                :detect-input="false"
              >
                <b-form-group>
                  <h5>Start Time</h5>
                  <flat-pickr
                    v-model="eventData.period_start"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', altInput: true,
                               altFormat: 'h:i K',}"
                    @input="validationContext.validate(eventData.period_start)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="End Time"
                rules="required"
              >
                <b-form-group>
                  <h5>End Time</h5>
                  <flat-pickr
                    v-model="eventData.period_end"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',minTime:eventData.period_start, altInput: true,
                               altFormat: 'h:i K',}"
                    @input="validationContext.validate(eventData.period_end)"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Presence Type"
                rules="required"
              >
                <b-form-group
                  label="Presence Type"
                  label-for="presence-type"
                >
                  <b-form-select
                    id="presence-type"
                    v-model="eventData.presence_type"
                    :options="presenceTypeOptions"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="2"
            >
              <b-form-group
                label="Schedule Auto Activate"
                label-for="notes"
                class="mb-2"
              >
                <b-form-checkbox
                  id="auto-activate"
                  v-model="eventData.auto_activate_flag"
                  variant="primary"
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="eventData.auto_activate_flag"
              md="2"
            >
              <date-time
                id="activate-time"
                :datetime.sync="eventData.publish_at"
                name="Activate Booking Time"
                rules="required"
                :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today', maxDate:eventData.start_time}"
              />
            </b-col>
          </b-form-row>

          <validation-provider
            #default="validationContext"
            name="General Notes"
            rules=""
          >
            <b-form-group
              label="General Notes"
              label-for="notes"
              class="mb-2"
            >
              <b-form-textarea
                id="notes"
                v-model="eventData.activity_description"
                placeholder="General notes for this event"
                rows="3"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <back class="mr-1" />

          <submit-button
            v-if="!isEventCreated"
            :handle-submit="handleSubmit"
            :submit="createEvent"
          />
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import getEventsID from '@/common/compositions/PrayerBookings/getEventsID'
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import DateTime from '../../common/FormInputs/DateTime.vue'

export default {
  name: 'AddEvent',
  components: {
    flatPickr,
    vSelect,
    Back,
    SubmitButton,
    DateTime,
  },
  data() {
    return {
      weekDays: [
        { id: 0, name: 'Saturday' },
        { id: 1, name: 'Sunday' },
        { id: 2, name: 'Monday' },
        { id: 3, name: 'Tuesday' },
        { id: 4, name: 'Wednesday' },
        { id: 5, name: 'Thursday' },
        { id: 6, name: 'Friday' },
      ],
      eventData: {
        entity_id: this.$store.getters['mainEntity/getEntityId'],
        activity_name: null,
        activity_description: null,
        frequency: 'once',
        start_time: '',
        end_time: '',
        period_start: '',
        period_end: '',
        weekly_dates: [],
        selected_dates: [],
        activate_time: 0,
        auto_activate_flag: false,
      },
      occurrenceTypes: [
        { text: 'Once', value: 'once' },
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Selected Dates', value: 'selected_dates' },
      ],
      multiDate: null,
      dateRange: null,
      isEventCreated: false,
      presenceTypeOptions: [
        { text: 'In Person', value: 'offline' },
        { text: 'Online', value: 'online' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  computed: {
    canUserAddEvent: {
      get() {
        return this.$can('store', 'PrayerBookingController')
      },
    },
  },
  methods: {
    createEvent() {
      return this.$store.dispatch('addEvents/createEvent', this.eventData).then(() => {
        this.successfulOperationAlert('Event is created successfully')
        this.$emit('update:event-data', this.eventData)
        this.isEventCreated = true
      })
    },
    convertDateRangeToArray() {
      [this.eventData.start_time, this.eventData.end_time] = this.dateRange.split(' to ')
    },
    convertMultiDateToArray() {
      this.eventData.selected_dates = this.multiDate.split(',')
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const {
      getEventsIDs,
      eventsIDsArray,
    } = getEventsID()
    const { successfulOperationAlert } = handleAlerts()
    return {
      getEventsIDs,
      eventsIDsArray,
      successfulOperationAlert,

      getValidationState,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
