<template>
  <div>
    <b-row
      class="mb-2"
      align-h="end"
    >
      <b-col
        md="2"
        class="mt-2 mt-md-0"
      >
        <b-button
          :disabled="!(isEventsCreated.length && !!selectedEventId)"
          variant="primary"
          class="mr-2 btn-block"
          @click="showAddSessionFormAndHideEditForm()"
        >
          <span class="text-nowrap">Add New Session</span>
        </b-button>
      </b-col>
    </b-row>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="getSelectedSessionList"
      show-empty
      empty-text="There is no Sessions for this Event!"
      :fields="sessionsTableColumns"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
    >
      <template #cell(session_start)="{item}">
        <div class="text-nowrap">
          {{ item.session_start }}
        </div>
      </template>
      <template #cell(session_end)="{item}">
        <div class="text-nowrap">
          {{ item.session_end }}
        </div>
      </template>
      <template #cell(required_vaccination)="{item}">
        <b-form-checkbox
          v-model="item.required_vaccination"
          type="checkbox"
          :value="1"
          :unchecked-value="0"
          disabled
        />
      </template>
      <template #cell(target_gender)="{item}">
        <span class="text-capitalize">{{ item.session_target_gender }}</span>
      </template>
      <template #cell(session_status)="{item}">
        <b-form-checkbox
          v-model="item.session_status"
          type="checkbox"
          value="active"
          unchecked-value="inactive"
          @change="updateSessionStatus(item.id)"
        />
      </template>
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="showEditSessionFormAndHideAddForm(item)">
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
          <b-dropdown-item @click="duplicateSession(item)">
            <feather-icon
              icon="CopyIcon"
            />
            <span> Duplicate </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import sessionStatus from '@/common/compositions/PrayerBookings/updateSessionStatus'
import session from '@/common/compositions/PrayerBookings/duplicateSession'

export default {
  name: 'SessionsTable',
  props: {
    selectedEventId: { type: Number, default: null },
  },
  data() {
    return {
      sessionsTableColumns: [
        { key: 'topic', label: 'Topic' },
        { key: 'action_type' },
        { key: 'session_address', label: 'Address', formatter: v => v.slice(0, 20) },
        { key: 'session_start', label: 'Start' },
        { key: 'session_end', label: 'End' },
        { key: 'session_languages', label: 'Languages' },
        { key: 'session_status', label: 'Status' },
        { key: 'target_gender', label: 'Audience Gender' },
        { key: 'required_vaccination', label: 'Required Vaccination' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { updateSessionStatus } = sessionStatus()
    const table = ref(null)
    const { duplicateSessionPromise } = session()
    const duplicateSession = sessionData => {
      duplicateSessionPromise(sessionData).then(() => {
        table.value.refresh()
      })
    }
    return {
      duplicateSession,
      table,
      updateSessionStatus,
    }
  },
  computed: {
    ...mapGetters({ isEventsCreated: 'addEvents/getEventsList' }),
    sessionsListComputed: {
      get() {
        return this.getSelectedSessionList()
      },
    },
  },
  watch: {
    sessionsListComputed: {
      handler() {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
  methods: {
    getSelectedSessionList() {
      if (this.selectedEventId) {
        const promise = this.$activities.get(`internalops/prayer-activities/${this.selectedEventId}`)

        return promise.then(res => {
          const { sessions } = res.data.data
          this.$emit('update:eventActionType', sessions[0]?.action_type || null)
          return sessions || []
        })
      }
      return []
    },
    showAddSessionFormAndHideEditForm() {
      this.$emit('update:isAddSessionFormVisible', true)
      this.$emit('update:isEditSessionFormVisible', false)
    },
    showEditSessionFormAndHideAddForm(sessionData) {
      this.$emit('update:selected-session-to-edit', {
        ...sessionData,
        address: sessionData.address ? sessionData.address : {},
      })

      this.$emit('update:isEditSessionFormVisible', true)
      this.$emit('update:isAddSessionFormVisible', false)
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
