import { render, staticRenderFns } from "./addPrayerBooking.vue?vue&type=template&id=73231236&scoped=true&"
import script from "./addPrayerBooking.vue?vue&type=script&lang=js&"
export * from "./addPrayerBooking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73231236",
  null
  
)

export default component.exports