import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export default function getEventsID() {
  const events = computed(() => store.getters['addEvents/getEventsList'])

  const eventsIDsArray = ref([])
  const getEventsIDs = () => {
    events.value.forEach(event => {
      eventsIDsArray.value.push(event.id)
    })
  }

  return {
    eventsIDsArray,
    getEventsIDs,
  }
}
